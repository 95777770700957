import { useState } from "react";

import type { AppProps } from "next/app";

import "css/tailwind.css";
import { AxiosError } from "axios";
import { appWithTranslation } from "next-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount: number, error: unknown) => {
              if (process.env.NODE_ENV !== "production") {
                return false;
              }

              // Retry (max. 3 times) only if network error detected
              if (
                error instanceof AxiosError &&
                error.message === "Network request failed" &&
                failureCount <= 3
              ) {
                return true;
              }

              return false;
            },
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Component {...pageProps} />
      </Hydrate>
    </QueryClientProvider>
  );
}

export default appWithTranslation(App);
